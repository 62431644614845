import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { useI18n } from 'services/i18n';
import { Background, Container, Row, Col } from 'styles/grid';
import media from 'styles/media';
import Layout from 'components/layout';
import SEO from 'components/seo';
import FitleBenefits from 'components/fitle-benefits';
import ClientCase from 'components/client-case';
import Block from 'components/block';
import { SecondaryButton } from 'components/buttons';
import { BiggerText, BigText, PrimaryTitle, SecondaryTitle } from 'components/texts';
import Clock from 'images/icons/clock.svg';
import Integration from 'images/icons/integration.svg';
import Heart from 'images/icons/heart.svg';
import { DemoLink } from 'components/buttons';
import Helmet from 'react-helmet';

import LogoGaleriesLafayette from 'images/logos/galerieslafayette.svg';
import LogoKitsune from 'images/logos/maisonkitsune.svg';
import LogoAsphalte from 'images/logos/asphalte.svg';
import LogoBensimon from 'images/logos/bensimon.svg';
import LogoJules from 'images/logos/jules.svg';
import LogoVolcom from 'images/logos/volcom.svg';
import LogoEdenPark from 'images/logos/edenpark.svg';

const TitleTextCol = styled(Col)`
  ${media.md`
    margin-bottom: 5%;
    h1, p {
      text-align: left;
    }
    p {
      max-width: 480px;
    }
  `}
`;

const MainImageContainer = styled.div`
  position: relative;
  display: none;

  ${media.md`
    display: block;
  `}
`;

const MainCTA = styled(DemoLink)`
  display: block;
  width: 100%;
  margin: 40px auto 0 auto;
  max-width: 480px;
  ${media.md`
    margin: 60px 0 0 0;
  `}
`;

const PartnersLogo = styled.div`
  margin-top: 50px;
  display: grid;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 26px);
  grid-row-gap: 60px;
  grid-template-areas:
    'asphalte kitsune'
    'bensimon galerie'
    'jules volcom'
    'edenpark edenpark';

  ${media.md`
    display: flex;
    justify-content: space-between;
  `}
`;

const Asphalte = styled.div`
  text-align: center;
  grid-area: asphalte;
`;
const Kitsune = styled.div`
  text-align: center;
  grid-area: kitsune;
`;
const Bensimon = styled.div`
  text-align: center;
  grid-area: bensimon;
`;
const Galerie = styled.div`
  text-align: center;
  grid-area: galerie;
`;
const Jules = styled.div`
  text-align: center;
  grid-area: jules;
`;
const Volcom = styled.div`
  text-align: center;
  grid-area: volcom;
`;
const EdenPark = styled.div`
  text-align: center;
  grid-area: edenpark;
`;

const SecondaryLinkCol = styled(Col)`
  margin: 40px 0 60px 0;
`;

const RoadmapContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 100px 0;
  ${media.md`
    padding: 120px 0;
  `}
`;

const RoadmapTitle = styled(Row)`
  order: 1;
  margin-bottom: 40px;
  ${media.md`
    margin-bottom: 100px;
  `}
`;

const RoadmapButton = styled(Row)`
  margin-top: 40px;
  order: 2;
  margin-bottom: 60px;
  ${media.md`
    margin-bottom: 0;
    order: 3;
    margin-top: 60px;
  `}
`;

const RoadmapBlocks = styled(Row)`
  order: 3;
  ${media.md`
    order: 2;
  `}
`;

const ImpactCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IndexPage = () => {
  const [t, language] = useI18n();

  const data = useStaticQuery(graphql`
    query {
      illustration_fr: file(relativePath: { eq: "homepage/fr/illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      illustration_en: file(relativePath: { eq: "homepage/en/illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      features_fr: allFile(filter: { relativeDirectory: { eq: "homepage/fr/features" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      features_en: allFile(filter: { relativeDirectory: { eq: "homepage/en/features" } }) {
        nodes {
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
      dashboard_fr: file(relativePath: { eq: "homepage/fr/dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 960, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      dashboard_en: file(relativePath: { eq: "homepage/en/dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 960, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);

  const featuresMapping = {
    fr: data.features_fr.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
    en: data.features_en.nodes.reduce((mapping, image) => {
      mapping[image.name] = image.childImageSharp;
      return mapping;
    }, {}),
  };

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript">
          {`
          _linkedin_partner_id = "5304049";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);`}
        </script>
        <script type="text/javascript">
          {`
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`}
        </script>
        <noscript>{`
          <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=5304049&fmt=gif" />
          `}</noscript>
      </Helmet>
      <SEO title={t('seo.title.index')} lang={language} description={t('seo.description.index')} />
      <section>
        <Row>
          <TitleTextCol md={7} sm={12}>
            <PrimaryTitle>
              {t('index.title')}
              <PrimaryTitle as="span" color="fitle">
                {t('index.title-size')}
              </PrimaryTitle>
            </PrimaryTitle>
            <BigText>{t('index.explanation')}</BigText>
            <MainCTA id="ga-demo-homepage" to="/demo/">
              {t('index.appointment')}
            </MainCTA>
          </TitleTextCol>
          <Col md={5} sm={12}>
            <MainImageContainer>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: '40px',
                  width: '80%',
                }}
              >
                <Img
                  fluid={data['illustration_' + language].childImageSharp.fluid}
                  alt={t('seo.alt.index.fitleSizingSolution')}
                />
              </div>
            </MainImageContainer>
          </Col>
        </Row>
      </section>
      <section>
        <BigText desktopAlign="center" weight="bold">
          {t('index.client-list')}
        </BigText>
        <PartnersLogo>
          <Asphalte>
            <LogoAsphalte height="30px" width="auto" />
          </Asphalte>
          <Kitsune>
            <LogoKitsune />
          </Kitsune>
          <Bensimon>
            <LogoBensimon height="20px" />
          </Bensimon>
          <Galerie>
            <LogoGaleriesLafayette height="80px" width="auto" />
          </Galerie>
          <Jules>
            <LogoJules height="25px" width="auto" />
          </Jules>
          <Volcom>
            <LogoVolcom width="100px" height="70px" />
          </Volcom>
          <EdenPark>
            <LogoEdenPark height="30px" />
          </EdenPark>
        </PartnersLogo>
      </section>
      <section style={{ padding: 30 }}>
        <FitleBenefits />
      </section>
      <Background style={{ display: 'inline-block' }}>
        <section style={{ margin: '0 auto' }}>
          <ClientCase />
        </section>
      </Background>
      <section>
        <Container>
          <Row justify="center">
            <Col>
              <SecondaryTitle style={{ marginBottom: 0 }}>{t('index.how-it-works')}</SecondaryTitle>
            </Col>
          </Row>
          <Block
            titleText={t('index.no-measure')}
            imageFluid={featuresMapping[language].feature1.fluid}
            alt={t('seo.alt.index.pluginMorphology')}
          >
            <BiggerText>{t('index.reco')}</BiggerText>
            <SecondaryButton to="/plugin" text={t('index.more')} />
          </Block>
          <Block
            titleText={t('index.comfort')}
            imageFluid={featuresMapping[language].feature2.fluid}
            alt={t('seo.alt.index.pluginComfort')}
            isImageLeft={true}
          >
            <BiggerText>{t('index.comfort-description')}</BiggerText>
            <SecondaryButton to="/plugin" text={t('index.more')} />
          </Block>
          <Block
            titleText={t('index.fast-purchase')}
            imageFluid={featuresMapping[language].feature3.fluid}
            alt={t('seo.alt.index.pluginWidget')}
          >
            <BiggerText>{t('index.one-form')}</BiggerText>
            <SecondaryButton to="/plugin" text={t('index.more')} />
          </Block>
        </Container>
      </section>
      <section>
        <Container>
          <Row justify="center">
            <Col sm={12} md={10}>
              <SecondaryTitle>
                {t('index.follow')}
                <SecondaryTitle as="span" color="fitle">
                  {t('index.realtime-performance')}
                </SecondaryTitle>
                {t('index.from-dashboard')}
              </SecondaryTitle>
            </Col>
          </Row>
          <Row justify="center">
            <SecondaryLinkCol sm="content" md="content">
              <SecondaryButton to="/dashboard" text={t('index.more-dashboard')} />
            </SecondaryLinkCol>
          </Row>
          <Row justify="center">
            <Col sm={12} md={8}>
              <Img
                fluid={data['dashboard_' + language].childImageSharp.fluid}
                alt={t('seo.alt.index.dashboardEcommercePerformance')}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Background>
        <section>
          <RoadmapContainer>
            <RoadmapTitle justify="center">
              <Col>
                <SecondaryTitle>
                  {t('index.integrate-fitle')}
                  <SecondaryTitle as="span" color="fitle">
                    {t('index.no-impact')}
                  </SecondaryTitle>
                </SecondaryTitle>
              </Col>
            </RoadmapTitle>
            <RoadmapButton justify="center">
              <Col sm="content" md="content">
                <SecondaryButton to="/integration" text={t('index.more-integration')} />
              </Col>
            </RoadmapButton>
            <RoadmapBlocks>
              <ImpactCol sm={12} md={4}>
                <Integration width={50} height={50} />
                <BiggerText weight="bold" desktopAlign="center">
                  {t('index.fitle-integration')}
                </BiggerText>
              </ImpactCol>
              <ImpactCol sm={12} md={4}>
                <Heart width={50} height={50} />
                <BiggerText weight="bold" desktopAlign="center">
                  {t('index.compatible')}
                </BiggerText>
              </ImpactCol>
              <ImpactCol sm={12} md={4}>
                <Clock width={50} height={50} />
                <BiggerText weight="bold" desktopAlign="center">
                  {t('index.from-dayone')}
                </BiggerText>
              </ImpactCol>
            </RoadmapBlocks>
          </RoadmapContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row justify="center">
            <Col>
              <SecondaryTitle style={{ marginBottom: 0 }}>
                {t('index.fitle-helps')}
                <SecondaryTitle as="span" color="fitle">
                  {t('index.manage-stocks')}
                </SecondaryTitle>
              </SecondaryTitle>
            </Col>
          </Row>
          <Block
            titleText={t('index.missed-sales')}
            imageFluid={featuresMapping[language].missedsales.fluid}
            alt={t('seo.alt.index.dashboardMissedSales')}
          >
            <BiggerText>{t('index.missed-sales-description')}</BiggerText>
            <SecondaryButton to="/dashboard" text={t('index.more')} />
          </Block>
        </Container>
      </section>
    </Layout>
  );
};

export default IndexPage;
