import React from 'react';
import styled from 'styled-components';

import { useI18n } from 'services/i18n';
import { Container, Row, Col } from 'styles/grid';
import media from 'styles/media';
import HomepageArrow from 'images/icons/homepage-arrow.svg';
import { SecondaryTitle, BiggerText, BigText } from './texts';

const CARD_TEXTS = [
  {
    problem: 'consumer-doubt',
    problemTitle: 'losing-sales',
    solutionTitle: 'conversion',
    solution: 'conversion-fitle',
  },
  {
    problem: 'consumer-return',
    problemTitle: 'losing-margin',
    solutionTitle: 'returns',
    solution: 'returns-fitle',
  },
  {
    problem: 'consumer-fidelity',
    problemTitle: 'losing-fidelity',
    solutionTitle: 'fidelity',
    solution: 'fidelity-fitle',
  },
];

const CardContainer = styled.div`
  max-width: 400px;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #181818;
  border-radius: 6px;
  height: 630px;
  padding: 40px 30px;
  ${media.md`
    padding: 40px 10px;
    height: 865px;
  `}
  ${media.lg`
  padding: 80px 30px;
  `}

  h2, p {
    margin: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Card = ({ t, text }) => (
  <CardContainer>
    <BigText desktopAlign="center">{t('index.' + text.problem)}</BigText>
    <SecondaryTitle>{t('index.' + text.problemTitle)}</SecondaryTitle>
    <div>
      <HomepageArrow height="100px" />
    </div>
    <BiggerText style={{ flexGrow: 0, marginTop: '10%' }} weight="bold">
      {t('index.with-fitle')}
    </BiggerText>
    <SecondaryTitle color="fitle">{t('index.' + text.solutionTitle)}</SecondaryTitle>
    <BigText desktopAlign="center">{t('index.' + text.solution)}</BigText>
  </CardContainer>
);

const FitleBenefits = () => {
  const [t] = useI18n();
  return (
    <Container>
      <Row align="center" justify="center">
        {CARD_TEXTS.map((text, i) => (
          <Col key={i} sm={12} md={4}>
            <Card key={i} t={t} text={text} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default FitleBenefits;
