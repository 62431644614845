import React from 'react';
import styled from 'styled-components';

import { useI18n } from 'services/i18n';
import colors from 'styles/colors';
import media from 'styles/media';
import Impact from 'images/icons/impact.svg';
import Quote from 'images/icons/quote.svg';
import { Text } from './texts';
import { SecondaryButton } from './buttons';
import { Container, Row, Col } from 'styles/grid';
import LogoShilton from 'images/logos/shilton.svg';

const TitleCol = styled(Col)`
  text-align: center;
  p {
    margin-top: 0;
  }
  svg {
    margin-bottom: 20px;
  }
  ${media.md`
    text-align: left;
    svg {
      margin-bottom: 0;
    }
  `}
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  justify-content: center;
  svg {
    flex: 0 0 24px;
    height: 24px;
    width: 24px;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0;
  }

  ${media.md`
    flex-wrap: nowrap;
    justify-content: flex-start;

    svg {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  `}
`;

const List = styled.ul`
  list-style: initial;
  margin-left: 20px;
`;

const ClientCaseContainer = styled(Container)`
  padding: 100px 15px;
  ${media.md`
    padding: 120px 15px;
  `}
  background-color: ${colors.lightBlueGrey};
`;

const ClientCase = () => {
  const [t] = useI18n();
  return (
    <ClientCaseContainer>
      <Row justify="center">
        <TitleCol sm={12} md={8}>
          <Text weight="bold" color="fitle">
            {t('index.client-case')}
          </Text>
          <LogoShilton width="150px" height="110px" />
        </TitleCol>
      </Row>
      <Row justify="center">
        <Col sm={12} md={8}>
          <Row>
            <Col>
              <ListContainer>
                <Quote />
                <Text>{t('index.shilton-quote')}</Text>
              </ListContainer>
              <Text style={{ fontSize: '0.85rem' }} weight="bold">
                {t('index.shilton-title-name')},
                <Text style={{ fontSize: '0.85rem' }} as="span" weight="normal">
                  {t('index.shilton-title-job')}
                </Text>
              </Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListContainer>
                <Impact />
                <List>
                  <li>
                    <Text>
                      {t('index.shilton-visitors-with-fitle')}
                      <Text as="span" color="fitle" weight="bold">
                        {t('index.shilton-conversion')}
                      </Text>
                    </Text>
                  </li>
                  <li>
                    <Text>
                      {t('index.shilton-cart-with-fitle')}
                      <Text as="span" color="fitle" weight="bold">
                        {t('index.shilton-cart')}
                      </Text>
                    </Text>
                  </li>
                </List>
              </ListContainer>
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: 30 }}>
            <Col sm="content">
              <SecondaryButton
                text={t('index.more-stories')}
                style={{ marginTop: 0 }}
                to="/clients"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </ClientCaseContainer>
  );
};

export default ClientCase;
